@media(max-width:768px) {
  /* just for the profile card at phone-sized resolutions, can be replaced */
  .card.w-50 {
    width: 100%!important;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin: 2rem 0;
}

.videoWrapper>iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  border: none;
}

#login {
  text-align: center;
}
body {
  background: url('./images/bg-tile.png');
  color: white;
  font-size: 18px;
  font-family: mr-eaves-xl-modern, sans-serif;
}

header,h1,h2,h3,h4,h5,h6 {
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
}

button {
  text-transform: uppercase;
}

.card {
  color: #000;
}
.navbar {
  background-color: #040404;
  box-shadow: 0 0 8px black;
}

#discordFrame {
  display: block;
  margin: 3rem auto;
}

#user-profile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  } #user-profile .profile-avatar {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 12rem;
    width: 12rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-right: 2rem;
  } #user-profile .side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  } #user-profile .nickname-box {
    display: flex;
    align-items: center;
  } #user-profile .stats-box {
    line-height: 1rem;
    width: 100%;
}



#teamslist {
  display: flex;
  flex-wrap: wrap;
}
#teamslist > div {
  margin: 1rem;
}
